@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  background: #f4f3ee;
}

* {
  font-family: "Poppins", sans-serif;
}
