.tabel .tabel_categorii {
  background-color: #ceddca;
}
.tabel tr {
  background-color: #f4f3ed;
}
#AppDetail tr {
  background-color: #ceddca;
}

#AppDetail td {
  background-color: #f4f3ed;
}
#responseRGI br {
  display: none;
}
#responseRGI h4 {
  display: none;
}
#responseRGI {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
